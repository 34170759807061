import React from 'react'
import './index.scss';
import Img_Home from '../../img/home2.png'
import Img_About from '../../img/nosotros.png'
import Img_Solutions from '../../img/inovacion.png'
import Img_Tecnology from '../../img/tecnologia.png'
import Img_Contact from '../../img/contact.png'
import Img_Work from '../../img/portafolio.png'


const Header = () => {

    return(
        <>
            <div className="container-menu">
                <div className="submenu">
                    <img src={Img_Home} alt="img-home"></img>
                    <label><a href="#home">HOME</a></label>
                </div>
                <div className="submenu">
                    <img src={Img_About} alt="img-about"></img>
                    <label><a href="#About">ABOUT-US</a></label>
                </div>
                <div className="submenu">
                    <img src={Img_Solutions} alt="img-solutions"></img>
                    <label><a href="#solutions">SOLUTIONS</a></label>
                </div>
                <div className="submenu">
                    <img src={Img_Tecnology} alt="img-technology"></img>
                    <label><a href="#technology">TECHNOLOGY</a></label>
                </div>
                <div className="submenu">
                    <img src={Img_Work} alt="img-technology"></img>
                    <label><a href="#Work">WORK</a></label>
                </div>
                <div className="submenu">
                    <img src={Img_Contact} alt="img-technology"></img>
                    <label><a href="#Contact">CONTACT US</a></label>
                </div>
            </div>
        </>
    )
}

export default Header;