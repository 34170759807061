import React from 'react';
import axios from 'axios';
import './index.scss'
/*
const Actualizar = (id) => {    
    let valor = document.getElementsByClassName("actualiza").value;
    //alert(valor)
    return axios
      .post(`http://localhost:8080/api/prestadores/pass`, {
        valor,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
};
*/


export default class Lista extends React.Component{
    state={
        persons: [],
        pass:'',    
    }
    componentDidMount() {
        axios.get(`http://187.176.187.142:8080/api/prestadores`)
            .then(res=>{
                const persons = res.data;
                this.setState({persons});
            })
    }
    render(){
        return(
            <div>
             
                {
                this.state.persons.map(
                    id => (
                        <table className="listaPrestadores">
                        <tr>
                            <td>{id.idPrestadorServicios}</td>
                            <td>{id.nombre}</td>
                            <td>{id.password}</td>
                            <td>{id.passapp}</td>
                            <td>                    
                                    <input className="input" id={"username"+id.idPrestadorServicios} value={id.NombreCompleto}></input>        
                                    <input className="input" id={"email"+id.idPrestadorServicios} value={id.correo}></input> 
                                    <input className="input"  id={"password"+id.idPrestadorServicios} value={id.password}></input> 
                                    <input className="input"  id={"role"+id.idPrestadorServicios} value="user"></input> 
                                <button value={id.idPrestadorServicios}  >Actualizar pass</button>                                             
                            </td>
                        </tr>
                        </table>
                        )
                    )
                }        
            </div>
        )
    }
}

