import React, { Component } from 'react';
import Slider from 'infinite-react-carousel';
//Nickelodeon
import Img_Work_1 from '../../../../../img/Nickelodeon/Imagen1.png';
import Img_Work_2 from '../../../../../img/Nickelodeon/Imagen2.jpg';
import Img_Work_3 from '../../../../../img/Nickelodeon/Imagen3.jpg';
import Img_Work_4 from '../../../../../img/Nickelodeon/Imagen4.jpg';
//Disney
import Img_Disney_1 from '../../../../../img/Disney/Img1.jpg';
import Img_Disney_2 from '../../../../../img/Disney/Img2.jpg';
import Img_Disney_3 from '../../../../../img/Disney/Img3.jpg';
//Cartoon Networks
import Img_Cartoon_1 from '../../../../../img/CartoonNet/Img1.jpg';
import Img_Cartoon_2 from '../../../../../img/CartoonNet/Img2.jpg';
import Img_Cartoon_3 from '../../../../../img/CartoonNet/Img3.jpg';
import Img_Cartoon_4 from '../../../../../img/CartoonNet/Img4.jpg';
import Img_Cartoon_5 from '../../../../../img/CartoonNet/Img5.jpg';

//Netflix
import Img_Netflix_1 from '../../../../../img/Netflix/Img1.jpg';
import Img_Netflix_2 from '../../../../../img/Netflix/Img2.png';
import Img_Netflix_3 from '../../../../../img/Netflix/Img3.jpg';
import Img_Netflix_4 from '../../../../../img/Netflix/Img4.jpg';
import Img_Netflix_5 from '../../../../../img/Netflix/Img5.jpg';



import './index.scss'

export default class Carrusel extends Component {
  render() {
    const settings =  {
      
    };
    return (
      <div className="carrusel-container">
        <div className="span">client projects</div>
        <Slider {...settings } className="Slider">
      
          <div className="slide">          
            <div className="container-images-client">
              <div className="box">
                <img className="Imagenes" src={Img_Netflix_1} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Peaky Blinders</p>
                </div>
              </div>              
              <div className="box">
                <img className="Imagenes" src={Img_Netflix_2} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Stranger Things</p>
                </div>
              </div>
              <div className="box">
                <img className="Imagenes" src={Img_Netflix_3} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Atípico</p>
                </div>
              </div>      
              <div className="box">
                <img className="Imagenes" src={Img_Netflix_4} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Johnny Test</p>
                </div>
              </div>     
              <div className="box">
                <img className="Imagenes" src={Img_Netflix_5} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Beat Bugs</p>
                </div>
              </div>                                 
            </div>            
            <h3>Netflix</h3>
          </div>
          <div className="slide">          
            <div className="container-images-client">
              <div className="box">
                <img className="Imagenes" src={Img_Work_1} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Kappa Mikey</p>
                </div>
              </div>              
              <div className="box">
                <img className="Imagenes" src={Img_Work_2} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Las Aventuras de Jimmy Neutrón</p>
                </div>
              </div>
              <div className="box">
                <img className="Imagenes" src={Img_Work_3} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Rugrats crecidos</p>
                </div>
              </div>       
              <div className="box">
                <img className="Imagenes" src={Img_Work_4} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Rugrats en el jardín de niños</p>
                </div>
              </div>                    
            </div>            
            <h3>Nickelodeon</h3>
          </div>
          <div className="slide">          
            <div className="container-images-client">
              <div className="box">
                <img className="Imagenes" src={Img_Disney_1} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Aladín</p>
                </div>
              </div>              
              <div className="box">
                <img className="Imagenes" src={Img_Disney_2} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Fillmore</p>
                </div>
              </div>
              <div className="box">
                <img className="Imagenes" src={Img_Disney_3} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Maggie, una mosca con onda</p>
                </div>
              </div>                                  
            </div>            
            <h3>Disney</h3>
          </div>
          <div className="slide">          
            <div className="container-images-client">
              <div className="box">
                <img className="Imagenes" src={Img_Cartoon_1} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Las sombrias aventuras de Billy y Mandy</p>
                </div>
              </div>              
              <div className="box">
                <img className="Imagenes" src={Img_Cartoon_2} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Los Jóvenes titanes en accion</p>
                </div>
              </div>
              <div className="box">
                <img className="Imagenes" src={Img_Cartoon_3} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>El increible Mundo de Gumball</p>
                </div>
              </div>      
              <div className="box">
                <img className="Imagenes" src={Img_Cartoon_4} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Hora de aventura</p>
                </div>
              </div>     
              <div className="box">
                <img className="Imagenes" src={Img_Cartoon_5} alt="Img 1 Work"></img>
                <div className="details">
                  <i className="fas fa-map-marker-alt"></i>
                  <p>Mansión Foster para amigos imaginarios</p>
                </div>
              </div>                                 
            </div>            
            <h3>Cartoon Networks</h3>
          </div>
       
        </Slider>
      </div>
    );
  }
}