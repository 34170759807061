import React from 'react';
import axios from 'axios';
import './index.scss'
import AuthService from "../../../services/auth.service";
import { API_URL } from '../../../consts';
import Calendario from './calendario';

const currentUser = AuthService.getCurrentUser();



function FormatNumber({number}){
  return(
    <span style={{color:"black"}}>
      {new Intl.NumberFormat("ES-MX", {
        style:"currency",
        currency: "MXN"
      }).format(number)}
    </span>
  );
}

export default class Histo extends React.Component {
  state = {
    persons: []
  }
  
  componentDidMount() {
      if(currentUser){
        axios.get(`${API_URL}/api/test/pagos/`+currentUser.iduser)
        .then(res => {
          const persons = res.data;
          this.setState({ persons });
        })
      }  
  }
  
  estado(valor1, valor2){
    let Respuesta = ''
    if(valor1 === 'SI'){
      Respuesta = 'Revisado'
    }
    if(valor2 === 'SI'){
      Respuesta = 'Pagado'
    }
    return Respuesta
  }

  render() {
    return (
      <div className="container-Table-Historial">

        <ul className="container-header-Historial">
            <li className="container-Mes">Mes</li>
            <li className="container-Quincena">Periodo</li>
            <li className="container-Pagos">Pago</li>
            <li className="container-Pagos">Estatus</li>
        </ul>
        {
        this.state.persons.map(person => [
            <ul className="container-Historial">
                <li className="container-Mes">{person.mes}</li>
                <li className="container-Quincena">{person.quincena}</li>
                <li className="container-Pagos">
                  <FormatNumber number={person.pagoTotal}></FormatNumber>                
                </li>
                <li className="container-Pagos">{this.estado(person.Estatus, person.pagado)} </li>                
            </ul>
            ]
        )}

        <Calendario></Calendario>
      </div>
    )
  }
}