import React from 'react';
import './index.scss';
import Carrusel from './carrusel';
const Work = () => {
    return(
        <>  
            
            <div className="container-Work" id="Work">
                <div className="conatiner-subtitle-work">WORKS</div>
                <div className="container-text-work">
                    Our facilities are adapted with first class materials and equipment.
                    This allows us to fulfill our objective of offering a quality service.
                </div>
                <Carrusel></Carrusel>
            </div>
        </>
    )
}

export default Work;