import React from 'react';
import './index.scss'
const Contact = () => {
    return(
        <>
            <div className="container-Contact" id="Contact">
                <div className="conatiner-form">
                    <form>
                        <label>CONTACT US</label>
                        <input type="text" placeholder="Name"></input>
                        <input type="text" placeholder="Email"></input>
                        <input type="text" placeholder="Telephone"></input>
                        <input type="textarea" placeholder="Message" className="Message"></input>
                        
                        <input type="button" value="SEND" className="send"></input>
                    </form>
                </div>
                
            </div>
        </>
    )
}

export default Contact;