// Servicio de Autentificacion
import { API_URL} from "../consts";
import axios from "axios";


const API = API_URL + "/api/auth/";

const register = (username, email, password) => {
  return axios.post(API + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data)
        );
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const getCurrentidUser = () => {
  return JSON.parse(localStorage.getItem("idUser"));
};


export const datosCalendario = async data => {
  try {
      const response = await axios.get(
          `${API_URL}/api/test/calendario`
      )
      if(response.data){
          return response.data
      }
  }catch(error){
      return{
          hasError: true,
          error
      }
  }
};

const Objetos = {
  register:register,
  login:login,
  logout:logout,
  getCurrentUser:getCurrentUser,
  getCurrentidUser:getCurrentidUser,
};
export default Objetos;