import React from 'react';
import './index.scss'

const Footer = () => {
    return(
        <>
            <div className="container-Footer" id="Footer">
                <p>
                    All Rights Reserved. ©2021 Candiani Dubbing Studios  Design By: Vladimir Ortega
                </p>
            </div>
        </>
    )
}

export default Footer;