import React, { PureComponent} from 'react'
import jsPDF from 'jspdf'
import axios from 'axios'
import AuthService from "../../../../services/auth.service";

import { API_URL } from '../../../../consts';

const currentUser = AuthService.getCurrentUser();


export default class PDF extends PureComponent{
    //Inicializamos el constructor
    constructor(props){
        super(props)
        this.state = {
        }
    }



    componentDidMount() {
        if(currentUser){
            axios.get(`${API_URL}/api/test/pagos/ultimo/`+currentUser.iduser)
            .then(res => {
                const json = res.data;     
                //console.log(json) 
                this.setState({ json });
            })
            axios.get(`${API_URL}/api/test/intervenciones/`+currentUser.iduser)
            .then(res => {
                const participaciones = res.data;     
                //console.log(participaciones) 
                this.setState({ participaciones });
            })
        }
        
      }


    // funcion de Generador de PDF
    PDFGenerator = () => {
        var doc= new jsPDF('p','pt');
        doc.text(100,30, 'SERVICIOS DE TELEVISION MEXICANA, SA DE CV');
        doc.setFontSize(12);
      
    
        // Encabezado
        this.state.json.forEach(jsondata => {            
            doc.text(200,50, 'RECIBO DE HONORARIOS');            
            doc.text(150,70, 'ACTUACIONES DE PROYECTOS DEL: ' +
                    (jsondata.startRecibo)
                +
                ' AL: '+
                jsondata.endRecibo);
            doc.setFontSize(10);
            doc.text(20,100, 'CLAVE: '+jsondata.idUser);
            doc.text(20,85, 'NOMBRE: '+jsondata.nombreCompleto);
            doc.text(240,100, 'SINDICATO: '+jsondata.sindicato);
            doc.text(400,100, 'CREDENCIAL: '+jsondata.credencial);    
        })


        //Encabezado de tabla
        doc.rect(20, 120, 130, 20);
        doc.text(50,135, 'PROYECTO')        
        doc.rect(150, 120, 130, 20);
        doc.text(180,135, 'PERSONAJE')        
        doc.rect(280, 120, 130, 20);
        doc.text(290,135, 'FECHA DE GRABACION')        
        doc.rect(410, 120, 130, 20);
        doc.text(450,135, 'IMPORTE')  
        let co = 140; 
        //Proyecto
        this.state.participaciones.forEach(datos =>             
            {
            doc.rect(20, co, 130, 20)
            doc.text(30,co+15, datos.proyecto)             
            co=co+20;
            })
        let co1 = 140; 
        //Personajes
        this.state.participaciones.forEach(datos =>             
            {
            doc.rect(150, co1, 130, 20)
            doc.text(160,co1+15, datos.personaje)             
            co1=co1+20;
            })
        let co2 = 140; 
        //Fecha de Grabacion
        this.state.participaciones.forEach(datos =>             
            {
            doc.rect(280, co2, 130, 20)
            doc.text(285,co2+15, datos.fechaGrabacion)  
            co2=co2+20;
            })
        let co3 = 140; 
        let imp ="";
        //Fecha de Grabacion
        this.state.participaciones.forEach(datos =>                         
            {
            imp = datos.importe.toString()
            doc.rect(410, co3, 130, 20)
            doc.text(420,co3+15,imp)  
            co3=co3+20;
            })
            co=co+40;
        this.state.json.forEach(datosPago =>             
            {            
            let valor_importe = datosPago.importe.toString();
            let valor_Iva = datosPago.ivatotal.toString();
            let valor_Subtotal = datosPago.subtotal.toString();
            let valor_ISR = datosPago.retencionISR.toString();
            let valor_IVA = datosPago.retencionIVA.toString();
            let valor_cuotaSindical = datosPago.cuotaSindical.toString();
            let valor_Total = datosPago.pagoTotal.toString();
            
            co=co+15
            doc.text(360,co, "Importe:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_importe) 
            co=co+20
            doc.text(380,co, "IVA:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_Iva)
            co=co+20
            doc.text(340,co, "SUBTOTAL:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_Subtotal)  
            co=co+20
            doc.text(330,co, "Retencion ISR:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_ISR)
            co=co+20
            doc.text(330,co, "Retencion IVA:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_IVA)
            co=co+20
            doc.text(330,co, "Cuota Sindical:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_cuotaSindical)
            co=co+20
            doc.text(360,co, "TOTAL:")
            doc.rect(410, co-15, 130, 20)
            doc.text(420,co,valor_Total)
        })
    




        doc.save("pdfGenerado.pdf");
    }

    // Realizamos el render del componente de funcion
    render(){
        return(<button onClick={this.PDFGenerator}>Generar PDF</button>)
    }
}
