import React from 'react'
import './index.scss';
import Imagen from '../../img/micro-bocina.png';
import Logo from '../../img/AudioMasterCan.png';
import Dia from '../../img/dia.svg';
import Noche from '../../img/noche.svg';

import Header from '../header';
import About from './pages/aboutus';
import Solutions from './pages/solutions';
import Technology from './pages/tecnology'
import Contact from './pages/contact';
import Work from './pages/work';
import Footer from './pages/footer'
import { useState } from 'react';
import { useEffect } from 'react';

const HomePage = () => {

    const [checked, setChecked] = useState(localStorage.getItem("theme")==="dark" ? true : false);
    useEffect(()=> {
        document
            .getElementsByTagName("HTML")[0]
            .setAttribute("data-theme", localStorage.getItem("theme"));
    }, [checked]);


    const toggleThemeChange = () => {
        if (checked === false){
            localStorage.setItem("theme","dark");
            setChecked(true);
        }else{
            localStorage.setItem("theme","light");
            setChecked(false);
        }
    }


    return(
        
        <div className="principal-window">
        

        
        <Header></Header>
        <div className="general-home-container" id="home">
        
        <div className="options-container"> 
            <img alt="Dia" src={Dia}></img>
            <div className="toggle-container">
                <input type="checkbox" defaultChecked={checked} onChange={()=> toggleThemeChange()}></input>                
            </div>
            <img alt="Noche" src={Noche}></img>
        </div>





        

            <div className="encabezado">
                <button>
                    <a href="./login">
                        Login
                    </a>    
                </button>
            </div>
            <div className="Home">                  
                <img src={Imagen} className="Imagen-fondo" alt="Fondo"></img>                
                <div className="Presentacion">
                    <img src={Logo} className="Logo" alt="Logo"></img> 
                    <h2>AUDIOMASTER CANDIANI</h2>
                </div>
                
            </div>            
        </div>        
        <div className="Pages-Container">
            <About></About>
            <Solutions></Solutions>
            <Technology></Technology>            
            <Work></Work>
            <Contact></Contact>
            <Footer></Footer>
        </div>
        </ div>
    )
}

export default HomePage;