import React from 'react'
import './index.scss'
import axios from 'axios';
import { API_URL } from '../../../../consts';
import Moment from 'react-moment';

export default class Calendario extends React.Component {
    state = {
      data: []
    }
    
    componentDidMount() {
          axios.get(`${API_URL}/api/test/calendario`)
          .then(res => {
            const data = res.data;
            this.setState({ data });
          })
    }
    
    estado(valor1, valor2){
      let Respuesta = ''
      if(valor1 === 'SI'){
        Respuesta = 'Revisado'
      }
      if(valor2 === 'SI'){
        Respuesta = 'Pagado'
      }
      return Respuesta
    }
  
    render() {
      return (
        <div className="calendarContainer">
          <p>Calendario de Pagos</p>
          <ul className="container-header-Historial">
              <li className="container-Mes-Header-Calendario">Mes</li>
              <li className="container-Quincena-Header-Calendario">Periodo</li>
              <li className="container-del">del</li>
              <li className="container-al">al</li>
              <li className="container-Pago">Pago</li>
          </ul>
          {
          this.state.data.map(person => [
              <ul className="container-Historial">
                  <li className="container-Mes-Calendario">
                    {person.mes}
                  </li>
                  <li className="container-Quincena-Calendario">
                    {person.quincena}
                  </li>
                  <li className="container-Pagos-Calendario">
                    <Moment format="YYYY/MM/DD">
                      {person.inicioRecibo}
                    </Moment>                                        
                  </li>
                  <li className="container-Pagos-Calendario">
                    <Moment format="YYYY/MM/DD">
                      {person.finRecibo}
                    </Moment>                    
                  </li>
                  <li className="container-Pagos-Calendario">
                    <Moment format="YYYY/MM/DD">
                      {person.fechaPago}
                    </Moment>                                       
                  </li>                
              </ul>
              ]
          )}
        </div>
      )
    }
  }


  