import React from 'react';
import axios from 'axios';
import './index.scss'
import AuthService from "../../../services/auth.service";
import { API_URL } from '../../../consts';
import Moment from 'react-moment';
const currentUser = AuthService.getCurrentUser();
function Actualizar(){
  window.location.reload();
}
function Mostrar(fecha1,fecha2) {
    // save date on datenow
    let dateNow = new Date();
    function convertirFecha(fecha,tipo){          
        //Estraemos el mes
        let yearNow,Mes, dayNow,hourNow;
        if(tipo==="fecha1"){
          console.log("Primer Fecha"+typeof(fecha)+fecha+"--");          
          yearNow = fecha.getFullYear();
          Mes = fecha.getMonth() + 1;    
          Mes = ('0' + Mes).slice(-2);
          dayNow = ('0' + fecha.getDate()).slice(-2);
          
          hourNow = ('0' + fecha.getHours()).slice(-2);
        }
        if(tipo==="fecha2"){
          fecha = new Date(fecha);
          console.log("Segunda Fecha"+typeof(fecha)+fecha+"--");
          yearNow = fecha.getFullYear();
          Mes = fecha.getMonth() + 1;    
          Mes = ('0' + Mes).slice(-2);
          dayNow = ('0' + fecha.getDate()).slice(-2);
          hourNow = ('0' + fecha.getHours()).slice(-2);
        }               
        
        
        const minuteNow = fecha.getMinutes();
        const seconsNow = fecha.getSeconds();
        const miliseconsNow = fecha.getMilliseconds();
        let Fecha = (
            yearNow+"-"+Mes+"-"+dayNow+"T"+
            hourNow+":"+minuteNow+":"+seconsNow+"."+
            miliseconsNow+"Z"
            );
        return Fecha;             
    }
    let Fecha = convertirFecha(dateNow,"fecha1");
    let Fecha1, Fecha2;
    if(fecha1 !==""){
      Fecha1 =convertirFecha(fecha1,"fecha2");
    }
    if(fecha2 !==""){
      Fecha2 =convertirFecha(fecha2,"fecha2");
    }
    
    
    console.log("------FECHAS: "+Fecha+"---Fecha1:"+Fecha1+"----Fecha2"+Fecha2);
  
    if(document.getElementById('Ventana1') && document.getElementById('Ventana2')){      
      console.log("---"+Fecha1+"------"+Fecha+"---"+Fecha2)
      if((Fecha1<Fecha) & (Fecha<Fecha2)){              
        console.log("---"+Fecha1+"---"+Fecha2)
        let Ventana1 = document.getElementById('Ventana1');
        Ventana1.style.display= "none";
      }else{
        let Ventana2 = document.getElementById('Ventana2');
        Ventana2.style.display= "none";
      }
    }    
}

function DisplayRecibos(existePDF,existeXML,Estatus){
  let PermitePDF = document.getElementById('PDF');
  let NoPermitePDF = document.getElementById('PDFSubido');
  let PermiteXML = document.getElementById('XML');
  let NoPermiteXML = document.getElementById('XMLSubido');

  
  if(existePDF === "SI"){
    if(PermitePDF && NoPermitePDF){
      PermitePDF.style.display = "none";
      NoPermitePDF.style.display = "flex";
     }   
  }else{
    if (Estatus!=="SI"){
      if(PermitePDF && NoPermitePDF){
        PermitePDF.style.display = "flex";
        NoPermitePDF.style.display = "none";
      }
    }           
  }
  
  if(existeXML === "SI"){
    if(NoPermiteXML && PermiteXML){
      PermiteXML.style.display = "none";
      NoPermiteXML.style.display = "flex";
     }   
  }else{
    if(Estatus!=="SI"){
      if(NoPermiteXML && PermiteXML){
        NoPermiteXML.style.display = "none";
        PermiteXML.style.display = "flex";
      }   
    }   
  }
}


export default class Factura extends React.Component {
constructor(){
  super();
  this.state = {
    json: [],
    participaciones:[],
    selectedFile:'',
    existPDF:'',
    existXML:'',
  }
  this.handleInputChange = this.handleInputChange.bind(this);
}
  
  componentDidMount() {
    if(currentUser){
      axios.get(`${API_URL}/api/test/pagos/ultimo/`+currentUser.iduser)
      .then(res => {
        const json = res.data;     
        this.setState({ json });
        Buscar();
      })
      axios.get(`${API_URL}/api/test/intervenciones/`+currentUser.iduser)
      .then(res => {
        const participaciones = res.data;     
        console.log(participaciones) 
        this.setState({ participaciones });
      })
    
    
      const Buscar = () => {
        let idUser = this.state.json.map(dat => dat.idUser);         
        let añoRe = this.state.json.map(dat => dat.año);
        let mesRe = this.state.json.map(dat => dat.mes);
        let quincenaRe = this.state.json.map(dat => dat.quincena);
        const nompdf = añoRe+"-"+mesRe+"-"+quincenaRe+"-"+idUser+".pdf";
        const nomxml = añoRe+"-"+mesRe+"-"+quincenaRe+"-"+idUser+".xml";
  
        axios.get(`${API_URL}/comprobararchivo/`+nompdf)
        .then(res => {
          const existPDF = res.data;     
          this.setState({ existPDF });
        })
        
        axios.get(`${API_URL}/comprobararchivo/`+nomxml)
        .then(res => {
          const existXML = res.data;     
          this.setState({ existXML });
        })    
      }
    }    
  }
  
handleInputChange(event) {
  this.setState({
      selectedFile: event.target.files[0],
    })
}

submitPDF(id, año, mes, quincena,extension){
  const data = new FormData()   
  data.append('file', this.state.selectedFile)
  let TipeExtension = document.getElementById("FilePDF").value;
  TipeExtension = TipeExtension.split(".")
  
  if(TipeExtension[1]==="pdf" || TipeExtension[1]==="PDF"){
    console.warn(this.state.selectedFile);
    let url = API_URL+"/subir";
    axios.post(url,data,{params:{
    nombre: id[0],
    año:año[0],
    mes:mes[0],
    quincena:quincena[0],
    ext : extension
    } // receive two parameter endpoint url ,form data 
    })
    .then(res => { // then print response status
      console.warn(res);
      Actualizar();
    }) 
  }
  else{
    alert("Debe de Elejir un archivo con la extension PDF")
  }
}
submitXML(id, año, mes, quincena,extension){
  const data = new FormData() 
  data.append('file', this.state.selectedFile)

  let TipeExtension = document.getElementById("FileXML").value;
  TipeExtension = TipeExtension.split(".")
  
  if(TipeExtension[1]==="xml" || TipeExtension[1]==="XML"){
    console.warn(this.state.selectedFile);
    let url = API_URL+"/subir";
    axios.post(url,data,{params:{
      nombre: id[0],
      año:año[0],
      mes:mes[0],
      quincena:quincena[0],
      ext : extension
    } // receive two parameter endpoint url ,form data 
    })
    .then(res => { // then print response status
        console.warn(res);
        Actualizar();
    })
  }else{
    alert("Debe de Elejir un archivo con la extension XML")
  }

}

ViewFile(id, inaño, inmes, inquincena){
  let user = id[0];
  let año = inaño[0];
  let mes = inmes[0];
  let quincena = inquincena[0];
  let ext = "pdf";
  let nombre = año+"-"+mes+"-"+quincena+"-"+user+"."+ext;
  let URL = API_URL + "/download/"+nombre;  
  
  axios({
    url: URL,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', (nombre)); 
    document.body.appendChild(link);
    link.click();
  });
}


Eliminar(id, inaño, inmes, inquincena,inextension){
  let user = id[0];
  let año = inaño[0];
  let mes = inmes[0];
  let quincena = inquincena[0];
  let ext = inextension;
  let nombre = año+"-"+mes+"-"+quincena+"-"+user+"."+ext;
  let url = API_URL + "/eliminararchivo/"+nombre;
  axios.post(url)
  .then(res => { // then print response status
      console.warn(res);
      Actualizar();
  })
}

  render() {
    //{Mostrar(this.state.json.map(fecha1 => fecha1.initup),this.state.json.map(fecha1 => fecha1.finup))}
    return (
      <div className="container-general-recibo">
      <div className="container-Table-Recibo">      
        {
        this.state.json.map(person => [
            <ul className="container-header">             
              <li>
                <h5>fecha para subir factura del:                   
                  <Moment format="YYYY-MM-DD hh:mm">
                    {(person.initup)}
                  </Moment>
                  <span> al: </span> 
                  <Moment format="YYYY-MM-DD hh:mm">
                    {(person.finup)}
                  </Moment>
                </h5>
              </li>     
            </ul>
            ]
        )}
      


        {Mostrar(this.state.json.map(fecha1 => fecha1.initup),this.state.json.map(fecha1 => fecha1.finup))}
        {DisplayRecibos(this.state.existPDF.existe,this.state.existXML.existe,this.state.json.map(estado =>estado.Estatus))}
      
        <div id="MostrarBotones">
            <div id="Ventana1">
              <label>No son fechas para subir su Factura</label>
            </div>
            <div id="Ventana2" className="Ventana2">        
              <div className="PDF" id="PDF">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="text-white">Seleccionar Archivo PDF:</label>
                    <input type="file" accept=".pdf" className="form-control" name="upload_file" id="FilePDF" onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6">
                      <button type="submit" className="btn btn-dark"  onClick={()=>this.submitPDF(
                      this.state.json.map(dat => dat.idUser),
                      this.state.json.map(dat => dat.año),
                      this.state.json.map(dat => dat.mes),
                      this.state.json.map(dat => dat.quincena),
                      'pdf'
                    )}>Guardar</button>
                  </div>
                </div>
              </div>
             
              <div className="PDFSubido" id="PDFSubido">
                      <label>PDF Subido</label>
                      <button type="submit" className="btn btn-dark"  onClick={()=>this.Eliminar(
                        this.state.json.map(dat => dat.idUser),
                        this.state.json.map(dat => dat.año),
                        this.state.json.map(dat => dat.mes),
                        this.state.json.map(dat => dat.quincena),
                        'pdf'
                      )}>Eliminar PDF</button>
  
                      <button type="submit" className="btn btn-dark"  onClick={()=>this.ViewFile(
                        this.state.json.map(dat => dat.idUser),
                        this.state.json.map(dat => dat.año),
                        this.state.json.map(dat => dat.mes),
                        this.state.json.map(dat => dat.quincena),
                        'pdf'
                      )}>Visualizar archivo</button>
              </div>

              <div className="XML" id="XML">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="text-white">Seleccionar Archivo XML:</label>
                    <input type="file" accept=".xml" className="form-control" name="upload_file" id="FileXML" onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6">
                    <button type="submit" className="btn btn-dark" onClick={()=>this.submitXML(
                      this.state.json.map(dat => dat.idUser),
                      this.state.json.map(dat => dat.año),
                      this.state.json.map(dat => dat.mes),
                      this.state.json.map(dat => dat.quincena),
                      'xml'
                    )}>Guardar</button>
                  </div>
                </div>
              </div>
              <div className="XMLSubido" id="XMLSubido">
                <label>XML Subido</label>
                <button type="submit" className="btn btn-dark" onClick={()=>this.Eliminar(
                    this.state.json.map(dat => dat.idUser),
                    this.state.json.map(dat => dat.año),
                    this.state.json.map(dat => dat.mes),
                    this.state.json.map(dat => dat.quincena),
                    'xml'
                  )}>Eliminar XML</button>
              </div>

            </div>
        </div>      
      </div>     
    </div>
    )
  }  
}