import React from 'react';
import './index.scss';
const About = () => {
    return(
    <>
        <div className="container-about" id="About">
            <div className="container-subtitle">
                ABOUT US
            </div>
            <div className="container-text">                
                Audiomaster Candiani has more than sixty-three years of experience in dubbing for the most important international producers and distributors in the entertainment industry. 
                <br></br><br></br>We are a company that meets the highest audio and video quality standards for any distribution platform.                
            </div>

        </div>
    </>       
    )
}

export default About;