import React from 'react'
import './index.scss';

const Technology = () => {
    return(
        <>
            <div className="container-technology" id="technology">
                <div className="tenchnology-subtitle">
                TECHNOLOGY + TALENT
                </div>
                <div className="technology-text">           
Throughout our career, we have followed the latest technological innovations (JBL, DYNAUDIO, NEUMANN, SSL, FOCUSRITE, APPLE, SONNET, CROWN, SENNHEISER, AVID) for the formation of the best work equipment.
<br></br>
We offer:
<br></br>
<ul>
    <li>Videos in Dolby Vision format, HDR, SDR, 4K, UHD, 2K, HD</li>
    <li>Dolby Atmos, 7.1, Stereo and Surround 5.1 sound format</li>
    <li>Avid – Pro Tools Ultimate</li>
</ul>


                </div>
            </div>
        </>
    )
}
export default Technology;