import React from 'react';
import Router from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
  document.title= "Audiomaster";
  return (
    <BrowserRouter>   
      <div className="main-container">
        <Router />
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;