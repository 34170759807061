import React from 'react';
import axios from 'axios';
import './index.scss'
import PDF from './pdf'
import AuthService from "../../../services/auth.service";
import { API_URL } from '../../../consts';
import Moment from 'react-moment';
const currentUser = AuthService.getCurrentUser();


function FormatNumber({number}){
  return(
    <span style={{color:"black"}}>
      {new Intl.NumberFormat("ES-MX", {
        style:"currency",
        currency: "MXN"
      }).format(number)}
    </span>
  );
}


export default class Recibo extends React.Component {
  state = {
    json: [],
    participaciones:[],
  }
  
  componentDidMount() {
    if(currentUser){
      axios.get(`${API_URL}/api/test/pagos/ultimo/`+currentUser.iduser)
      .then(res => {        
        const json = res.data;             
        this.setState({ json });
        
      })
     
      console.log("-->Prueba:",this.json)  


      axios.get(`${API_URL}/api/test/intervenciones/`+currentUser.iduser)
      .then(res => {        
        const participaciones = res.data;            
        this.setState({ participaciones });
        console.log("-->Prueba:",participaciones)
      })
        
    }
  }
  
  render() {
    return (
      <div className="container-general-recibo">
      <div className="container-Table-Recibo">      
        {
        this.state.json.map(person => [
            <ul className="container-header">
              <li>Recibo de Honorarios</li>
              <li>Pagos del mes: {person.mes}, periodo: {person.quincena}</li>          
              <li>
                <h4>
                    Actuaciones de de proyectos del :
                    <Moment format="YYYY/MM/DD hh:hh">
                      {person.startRecibo}
                    </Moment>  
                    <span> al: </span>                     
                    <Moment format="YYYY/MM/DD hh:mm">
                      {person.endRecibo}
                    </Moment>                     
                </h4>
                <h5>fecha para subir factura del: 
                  <Moment format="YYYY/MM/DD hh:hh">
                    {person.initup}
                  </Moment>
                  <span> al: </span> 
                  <Moment format="YYYY/MM/DD hh:mm">
                    {person.finup}
                  </Moment>                 
                </h5>
              </li>     
            </ul>
            ]
        )}
        <ul className="header-container-recibo">
          <li>PROYECTO</li>
          <li>PERSONAJE</li>
          <li>FECHA DE GRABACIÓN</li>
          <li>IMPORTE</li>
        </ul>
        {
        this.state.participaciones.map(inter => [
            <ul className="container-body-Recibo">
              <li>{inter.proyecto}</li> 
              <li>{inter.personaje}</li>  
              <li>
                <Moment format="YYYY/MM/DD">
                  {inter.fechaGrabacion}
                </Moment>
              </li>  
              <li>
                <FormatNumber number={inter.importe}></FormatNumber>
              </li>          
            </ul>
            ]
        )}
        {
        this.state.json.map(person => [
            <ul className="container-footer-recibos">
              <li></li>
              <li className="subtitle-footer">
                <ul>
                  <li>IMPORTE:</li>
                  <li>IVA:</li>
                  <li>SUBTOTAL:</li>
                  <li>RETENCION ISR:</li>
                  <li>RETENCION IVA:</li>
                  <li>CUOTA SINDICAL:</li>
                  <li></li>
                  <li>TOTAL:</li>
                </ul>
              </li>
              <li className="data-footer">
                <ul>
                  <li className="list-footer">
                    <FormatNumber number={person.importe}></FormatNumber> 
                  </li> 
                  <li className="list-footer">
                    <FormatNumber number={person.ivatotal}></FormatNumber>                    
                  </li> 
                  <li className="list-footer">
                    <FormatNumber number={person.subtotal}></FormatNumber>                                        
                  </li> 
                  <li className="list-footer">
                    <FormatNumber number={person.retencionISR}></FormatNumber>                    
                  </li>          
                  <li className="list-footer">
                    <FormatNumber number={person.retencionIVA}></FormatNumber>                     
                  </li> 
                  <li className="list-footer">
                    <FormatNumber number={person.cuotaSindical}></FormatNumber>                    
                  </li> 
                  <li className="list-footer"></li> 
                  <li className="list-footer">
                    <FormatNumber number={person.pagoTotal}></FormatNumber>                     
                  </li> 
                </ul>
              </li>              
            </ul>
            ]
        )}       
      </div>
      <div>
        <PDF></PDF>
      </div>
    </div>
    )
  }
}