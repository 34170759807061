import React from "react";
import AuthService from "../../services/auth.service";
import './index.scss';
import './recibos.scss';
import './facturas.scss';
//import {useState, useEffect} from 'react';
import Histo from './historial';
import Recibo from './recibopdf';
import Factura from './facturas';
import {createBrowserHistory} from 'history'
import Mensaje from './message'


const ConseguirMes= () => {
    let DateInput = document.getElementById("fecha");
    let ConseguirFecha =  new Date();
    let Fecha = (
                  ConseguirFecha.getFullYear()+"-"+
                  (ConseguirFecha.getMonth()+1)+"-"+
                  ConseguirFecha.getDate()                  
                  );
    
    DateInput.innerHTML = "Fecha :  " + Fecha;
}

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();
  const Historial=()=>{
    ConseguirMes();
    let Historial = document.getElementById("historial");
    let Recibos = document.getElementById("recibos");
    let Facturas = document.getElementById("facturas");
    Historial.style.display = "flex";
    Recibos.style.display = "none";
    Facturas.style.display = "none";
  }

  const Recibos=()=>{
    ConseguirMes();
    let Historial = document.getElementById("historial");
    let Recibos = document.getElementById("recibos");
    let Facturas = document.getElementById("facturas");
    Historial.style.display = "none";
    Recibos.style.display = "flex";
    Facturas.style.display = "none";
  }

  const Facturas=()=>{
    ConseguirMes();
    let Historial = document.getElementById("historial");
    let Recibos = document.getElementById("recibos");
    let Facturas = document.getElementById("facturas");
    Historial.style.display = "none";
    Recibos.style.display = "none";
    Facturas.style.display = "flex";
  }
  
  const history = createBrowserHistory();
  const CerrarSesion = () => {
    AuthService.logout();
    history.push("/");
    window.location.reload();
  }
  
  

  return (
    <div className="container-profile-actor">

      <div className="header-actor">
      <div className="container-datos">
        Bienvenido: 
        <br />
        {currentUser.username}        
      </div> 
      <div className="containerGeneralMessage">
        <Mensaje></Mensaje>
      </div>
      <div className="cerrar-session">
        <button onClick={CerrarSesion}>
          Cerrar Sesion
        </button>         
      </div>
      </div>
      
      <div className="container-menu-actores">
        <ul>
          <li onClick={Historial}>Historial</li>
          <li onClick={Recibos}>Recibos</li>
          <li onClick={Facturas}>Facturas</li>
        </ul>
      </div>


      <div className="container-window-content">
        <div id="fecha" className="fecha"></div>            
        <div className="container-historial" id="historial">          
            <Histo></Histo>
        </div>
        <div className="container-recibos" id="recibos">   
            <Recibo></Recibo>         
        </div>
        <div className="container-facturas" id="facturas">    
            <Factura></Factura>       
        </div>
      </div>
    </div>
  );


         
};

export default Profile;