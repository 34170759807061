import React from "react";
import axios from "axios";
import { API_URL } from '../../../consts';
import AuthService from "../../../services/auth.service";
import './index.scss';


const currentUser = AuthService.getCurrentUser();

export default class Mensaje extends React.Component {
    constructor(){
        super();
        this.state = {
          datos: [],
        }
    }
    
    componentDidMount() {
        if(currentUser){
          axios.get(`${API_URL}/api/test/pagos/ultimo/`+currentUser.iduser)
          .then(res => {
            const datos = res.data;     
            this.setState({ datos });            
          })
        }
    }       

    render(){
        return(
            <div className="containerMessage">
                
                <div className="Mensaje">
                <label>Mensaje: *</label>     {this.state.datos.map(valor=> valor.mensaje)}
                </div>                
                
            </div>
        )
    }
}