import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

//Import Components
import HomePage from '../components/homepage';
import Login from '../components/login/index.jsx';
import componentDidMount from '../components/login/prueba';
import Profile from '../components/profile'
import Lista from '../components/listaprestadores'
const Routes = () => (
    <Switch>
        <Route exact path = "/" component={HomePage} title="Audiomaster Candiani" />
        <Route exact path = "/Login" component={Login} />
        <Route exact path = "/Prueba" component={componentDidMount} />
        <Route exact path  ="/profile" component={Profile} />
        <Route exact path  ="/Lista" component={Lista} />

        <Redirect to='/'></Redirect>
    </Switch>
);

export default Routes;