import React from 'react'
import './index.scss';

const Solutions = () => {
    return(
        <>
            <div className="container-solutions" id="solutions">
                
                <div className="subtitle-solutions">
                    SOLUTIONS
                </div>
                <div className="text-solutions">

<h3>DUBBING</h3>                                 


All our services are based on our institutional comittment: "The attention of the audience should not be diverted, the dubbing should go unnoticed." That is why, for more than 63 years, we have been the # 1 alternative in Mexico.
<br></br>
We offer:
<br></br>
Delivery Packages
<ul>
<li>IMF/DCP Packaging</li>
<li>DCP</li>
<li>Compliance to Multiple Platforms</li>
<li>Secure File Transfer Server</li>
<li>Transcoding</li>
</ul>
Sound mastering
<ul>
    <li>ADR</li>
    <li>Mixing 5.1, 7.1 & Atmos and Conform</li>
    <li>M&E Creation</li>
</ul>
Dubbing
<ul>
    <li>Latin Spanish, English and Brazilian Portuguese</li>
    <li>CCSL, PLDL</li>
    <li>Remote Recording / ISDN</li>
    <li>M&E Recreation</li>
    <li>Audio Description</li>
</ul>
Subtitling
<ul>
    <li>Latin Spanish, English and Brazilian Portuguese</li>
    <li>Close Captioning</li>
    <li>CC/SDH</li>
</ul>
MASTER QUALITY CONTROL
<br></br>
Audiomaster Candiani proudly belongs to the "Netflix Post Partner Program", which certifies us as a laboratory that meets the high standards of quality and efficiency that new digital platforms demand in order to distribute their contents.
<br></br>
We offer:
<ul>
    <li>4K & UHD Media Analysis</li>
    <li>HDR Dolby Vision Mastering</li>
    <li>Digital Cinema QC</li>
    <li>Automated File-Based QC</li>
    <li>M&E QC</li>
</ul>
                </div>

            </div>
        </>
    )
}
export default Solutions;